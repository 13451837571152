<template>
    <div class="page-wrap">
      <div class="page-aside">
       <ProductsSide @change="changeProduct" :nav="productsNav" :defaultActive="curProduct.productId"></ProductsSide>
      </div>
      <div class="page-main flex-column">
        <SubNav :nav="subNav" :router="true"></SubNav>
        <div class="main padding-20"><router-view :curProduct='curProduct'></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import ProductsSide from '@/components/ProductsSide.vue'
import SubNav from '@/components/SubNav.vue'
export default {
  name: 'Entry',
  components: { ProductsSide,SubNav },
  data() {
    return {
      productsNav:[],
      curProduct:{},
      subNav:[{name:'周度预测模型',value:'/sciExpert/assignment/week'},{name:'月度预测模型',value:'/sciExpert/assignment/month'}],
    }
  },
  created(){
    this.productsNav=this.$store.state.loginResSCI.productList||[]
    this.curProduct=this.productsNav[0]||{}
  },
  methods:{
    changeProduct(val){
      this.curProduct=val
    }
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
